/* ===== Scrollbar CSS ===== */
/* Firefox */
html,
body,
#root {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  scrollbar-width: auto;
  scrollbar-color: #5c5c5c1c #ffffff;
}

/* Chrome, Edge, and Safari */
html::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 16px;
}

html::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  background: #ffffff;
}

html::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background-color: #5c5c5c81;
  border-radius: 10px;
  border: 4px solid #ffffff;
}
